import config from '../config';
import logo from '@michelin/acid-components/assets/images/logos/Michelin_C_S_WhiteBG_RGB_0621-01.svg';

const tiles = [
  {
    key: 'eventViewer',
    title: 'eventViewer.title',
    image: 'event',
    serviceName: 'event-viewer',
    description: 'eventViewer.description',
    link: config.eventViewer,
    requireLogin: false,
    requireAuth0Login: false,
    visible: (userProfile) => {
      return false;
    },
    sizes: {
      xs: 12,
      sm: 6,
      md: 6,
    },
  },
  {
    key: 'liveTrack',
    title: 'liveTrack.title',
    image: 'local_shipping',
    serviceName: 'live-track',
    description: 'liveTrack.description',
    callout: {
      title: 'liveTrack.callout.title',
      description: 'liveTrack.callout.description',
    },
    link: config.liveTrack,
    requireLogin: true,
    requireAuth0Login: false,
    visible: (userProfile) => {
      return true;
    },
    sizes: {
      xs: 12,
      sm: 6,
      md: 6,
    },
  },
  {
    key: 'esd',
    title: 'esd.title',
    image: 'article',
    serviceName: 'esd',
    description: 'esd.description',
    callout: {},
    link: config.esd,
    requireLogin: true,
    requireAuth0Login: false,
    visible: (userProfile) => {
      return true;
    },
    sizes: {
      xs: 12,
      sm: 6,
      md: 6,
    },
  },
  {
    key: 'dealerProfile',
    title: 'dealerProfile.title',
    image: 'supervisor_account',
    serviceName: 'dealerProfile',
    description: 'dealerProfile.description',
    link: config.dealerProfile,
    requireLogin: true,
    requireAuth0Login: false,
    visible: (userProfile) => {
      if (userProfile) {
        console.info(
          `dealerProfile: setting visibility for role ${userProfile.userRole}`
        );

        return userProfile.userRole === 'D' || userProfile.userRole === 'E';
      }

      return false;
    },
    sizes: {
      xs: 12,
      sm: 6,
      md: 6,
    },
  },
  {
    key: 'allPrefs',
    title: 'coreProfile.title',
    image: 'supervisor_account',
    serviceName: 'allPrefs',
    description: 'coreProfile.description',
    link: config.coreProfile,
    requireLogin: true,
    requireAuth0Login: false,
    visible: (userProfile) => {
      if (userProfile) {
        console.info(
          `coreProfile: setting visibility for role ${userProfile.userRole}`
        );

        return userProfile.userRole === 'F' || userProfile.userRole === 'E';
      }
      return false;
    },
    sizes: {
      xs: 12,
      sm: 6,
      md: 6,
    },
  },
  {
    key: 'maestroCentral',
    title: 'maestroCentral.title',
    image: logo,
    serviceName: 'maestroCentral',
    description: 'maestroCentral.description',
    link: config.maestroCentral,
    requireLogin: true,
    requireAuth0Login: false,
    visible: (userProfile) => {
      if (userProfile) {
        console.info(
          `maestroCentral: setting visibility for role ${userProfile.userRole}`
        );

        return userProfile.userRole === 'E';
      }
      return false;
    },
    sizes: {
      xs: 12,
      sm: 6,
      md: 6,
    },
  },
  {
    key: 'techAdmin',
    title: 'techAdmin.title',
    image: 'build',
    serviceName: 'tech-admin',
    callout: {
      title: 'techAdmin.callout.title',
      description: 'techAdmin.callout.description',
    },
    link: config.techAdmin,
    requireLogin: true,
    requireAuth0Login: true,
    visible: (userProfile) => {
      return true;
    },
    sizes: {
      xs: 12,
      sm: 6,
      md: 6,
    },
  },
  {
    key: 'DTSM',
    title: 'dtsm.title',
    image: 'library_books',
    serviceName: 'dtsm',
    description: 'dtsm.description',
    link: config.dtsm,
    requireLogin: true,
    requireAuth0Login: false,
    visible: (userProfile) => {
      if (userProfile) {
        console.info(
          `dtsm: setting visibility for role ${userProfile.userRole}`
        );
        return (
          userProfile.userRole === 'F' ||
          userProfile.userRole === 'E' ||
          userProfile.userRole === 'D'
        );
      }
      return false;
    },
    sizes: {
      xs: 12,
      sm: 6,
      md: 6,
    },
  },
  {
    key: 'ONCallDR',
    title: 'oncalldr.title',
    image: 'dashboard',
    serviceName: 'oncalldr',
    description: 'oncalldr.description',
    link: config.oncalldr,
    requireLogin: true,
    requireAuth0Login: false,
    visible: (userProfile) => {
      // per Yolande -- hiding this for now as project is on hold
      // if (userProfile) {
      //   console.info(
      //     `oncalldr: setting visibility for role ${userProfile.userRole}`
      //   );
      //   return (
      //     userProfile.userRole === 'F' ||
      //     userProfile.userRole === 'D' ||
      //     userProfile.userRole === 'E'
      //   );
      // }
      return false;
    },
    sizes: {
      xs: 12,
      sm: 6,
      md: 6,
    },
  },
];

export default tiles;
